import SingleBlog from "components/elements/SingleBlog";
import AboutUs from "components/views/AboutUs";
import Blogs from "components/views/Blogs";
import Features from "components/views/Features";
import Main from "components/views/Main";
import Pricing from "components/views/Pricing";
import { Route, Routes, useLocation } from "react-router-dom";

const AnimatedRoutes = (props) => {
  const { featuresRef } = props;
  const location = useLocation();
  return (
    <Routes location={location}>
      <Route exact path={"/"} element={<Main features={featuresRef} />} />
      <Route exact path={"/pricing"} element={<Pricing />} />
      <Route exact path={"/about-us"} element={<AboutUs />} />
      <Route exact path={"/blogs"} element={<Blogs />} />
      <Route exact path={"/blog/:id"} element={<SingleBlog />} />
      <Route exact path={"/features"} element={<Features />} />
    </Routes>
  );
};

export default AnimatedRoutes;
