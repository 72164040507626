import axios from "axios";
import { useEffect, useState } from "react";
import AboutBanner from "components/elements/AboutBanner";
import {
  Button,
  ButtonGroup,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";
import BlogCard from "components/elements/BlogCard";

const Blogs = () => {
  const [keevBlogs, setKeevBlogs] = useState(null);
  const [allBlogs, setAllBlogs] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [isSmallerThan425] = useMediaQuery("(max-width: 425px)");

  const fetchBlogs = async () => {
    try {
      const res = axios.get(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@algokeev"
      );
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBlogs().then((res) => {
      setKeevBlogs(res?.data?.items);
      setAllBlogs(res?.data?.items);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    const reg = /[\u0900-\u097F]+/;
    const hindiBlogs = keevBlogs?.filter((ele) => reg.test(ele.title));
    const englishBlogs = keevBlogs?.filter((ele) => !reg.test(ele.title));
    if (tabIndex === 1) {
      setAllBlogs(hindiBlogs);
    } else {
      setAllBlogs(englishBlogs);
    }
  }, [tabIndex, keevBlogs]);

  const buttons = ["English", "हिन्दी"];

  const onSearchArticles = (e) => {
    const reg = /[\u0900-\u097F]+/;
    if (e.target.value === "") {
      if (tabIndex === 1) {
        setAllBlogs(keevBlogs?.filter((ele) => reg.test(ele.title)));
      } else {
        setAllBlogs(keevBlogs?.filter((ele) => !reg.test(ele.title)));
      }
    } else {
      setAllBlogs(
        allBlogs.filter((ele) =>
          ele.title.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  return !isLoading ? (
    <>
      <AboutBanner
        title="Read articles about market & become a pro trader"
        description="we post everything about market here"
      />
      <Grid
        templateColumns={`repeat(${isSmallerThan425 ? 2 : 3}, 1fr)`}
        gap={4}
        my={"5%"}
        px="10%"
      >
        <GridItem w="100%" colSpan={2} color="#141414">
          <Text fontSize={28}>
            <Text as="span" color="#01a2bb">
              Newest{" "}
            </Text>
            articles for you
          </Text>
          <ButtonGroup size="sm" isAttached my="2%">
            {buttons.map((item, i) => {
              return (
                <Button
                  color={tabIndex === i ? "#fff" : "#888888"}
                  fontSize="16px"
                  border="none"
                  borderRadius={"2px"}
                  size="md"
                  bg={tabIndex === i ? "#01A2BB" : "#EBFAFC"}
                  _hover={{
                    bg: tabIndex === i ? "#01A2BB" : "#EBFAFC",
                  }}
                  key={i}
                  onClick={() => setTabIndex(i)}
                >
                  {item}
                </Button>
              );
            })}
          </ButtonGroup>
        </GridItem>
        <GridItem w="100%" colSpan={isSmallerThan425 && 2}>
          <InputGroup borderColor={"#bebebe"}>
            <Input placeholder="Search Articles" onChange={onSearchArticles} />
            <InputRightElement children={<Search2Icon />} />
          </InputGroup>
        </GridItem>
        {allBlogs.map((item, i) => {
          return (
            <GridItem w="100%" key={i} colSpan={isSmallerThan425 && 2}>
              <BlogCard data={item} />
            </GridItem>
          );
        })}
      </Grid>
    </>
  ) : (
    <Stack
      px="10%"
      py="2%"
      marginTop="71px"
      height="100vh"
      alignItems={"center"}
      justifyContent="center"
    >
      <Spinner color="#01a2bb" size="xl" />
    </Stack>
  );
};

export default Blogs;
