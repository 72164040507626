import { Box, Image, Stack, Text, useMediaQuery } from "@chakra-ui/react";
import FivePaisa from "assets/images/5paisa.jpg";
import anand from "assets/images/Anand.jpg";
import espresso from "assets/images/Espresso.png";
import Fyers from "assets/images/FYERS Logo.png";
import IIFL from "assets/images/IIFL.png";
import mangal from "assets/images/Mangal_keshav_logo.png";
import TradePlus from "assets/images/Tradeplus.png";
import Duck from "assets/images/duck.png";
import Jainam from "assets/images/jainam.png";
import Jiffy from "assets/images/jiffy.png";
import Rmoney from "assets/images/rmoney.png";
import upstox from "assets/images/upstox.png";
import Arrow from "assets/svgs/arrow.svg";
import Partner2 from "assets/svgs/partner-2.svg";
import Partner6 from "assets/svgs/partner-6.svg";
import Partner7 from "assets/svgs/partner-7.svg";
import { Link } from "react-router-dom";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Autoplay]);

const OurPartners = () => {
  const [isSmallerThan425] = useMediaQuery("(max-width: 425px)");

  const partners = [
    // {
    //   link: "//www.angelone.in/",
    //   icon: Partner1,
    // },
    {
      link: "//bigul.co/",
      icon: Partner2,
    },
    {
      link: "//open-account.fyers.in/?utm-source=AP-Leads&utm-medium=AP1455",
      icon: Fyers,
    },
    // {
    //   link: "//www.kotaksecurities.com/open-demat-account/",
    //   icon: Partner4,
    // },
    {
      link: "//tradesmartonline.in/?referrer_id=YMUM902",
      icon: TradePlus,
    },
    {
      link: "//signup.zerodha.com/",
      icon: Partner6,
    },
    {
      link: "//aliceblueonline.com/open-account-fill-kyc-request-call-back",
      icon: Partner7,
    },
    {
      link: "//choiceindia.com/open-free-demat-account",
      icon: Jiffy,
    },
    {
      link: "",
      icon: Jainam,
    },
    { link: "//ducksso.keev.tech/", icon: Duck },
    { link: "https://login.5paisa.com/", icon: FivePaisa },
    { link: "", icon: IIFL },
    { link: "", icon: Rmoney },
    { link: "https://upstox.com/", icon:upstox},
    { link: "https://espresso.keev.tech/", icon: espresso },
    { link: "", icon: anand },
    { link: "https://www.mangalkeshav.com/", icon: mangal },
   
  ];
  return (
    <Stack px="10%" py="5%" bg="#F5F5FF" position="relative" spacing={4}>
      <Image src={Arrow} position="absolute" top={"-5%"} />
      <Text fontSize={28} color="#141414">
        <Text color="#01a2bb" as="span">
          API{" "}
        </Text>
        Integration
      </Text>

      <Swiper
        spaceBetween={20}
        slidesPerView={isSmallerThan425 ? 2 : 5}
        loop={true}
        autoplay={{
          delay: 3000,
        }}
      >
        {partners.map((item, i) => {
          return (
            <SwiperSlide key={i}>
              <Box width="150px">
                <Link to={item.link} target="_blank">
                  <Image src={item.icon} width="100%" />
                </Link>
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Stack>
  );
};

export default OurPartners;
