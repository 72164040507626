import { ArrowUpIcon } from "@chakra-ui/icons";
import { Box, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

const BlogCard = (props) => {
  const { data } = props;
  const navigate = useNavigate();
  return (
    <Stack
      height="450px"
      cursor={"pointer"}
      position="relative"
      onClick={() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        navigate(`/blog/${data?.title?.split(" ").join("-")}`);
      }}
    >
      <Box position="relative">
        <Image src={data?.thumbnail} height="300px" objectFit={"cover"} />
        <Stack
          height="90px"
          p={2}
          position="absolute"
          bottom="0"
          background="rgba(255, 255, 255, 0.4)"
          backdropFilter="blur(12px)"
          width={"100%"}
        >
          <Text fontSize={18} color="#1c1c1c">
            {data?.author}
          </Text>
          <Text fontSize={18} color="#1c1c1c">
            {data?.pubDate.split(" ")[0]}
          </Text>
        </Stack>
      </Box>
      <Text fontSize={20}>{data?.title}</Text>
      <Text position="absolute" fontSize={18} bottom={0} color="#6C6C6C">
        Read Post <ArrowUpIcon transform="rotate(35deg)" w={5} h={5} mb={1} />
      </Text>
    </Stack>
  );
};

export default BlogCard;
