import Feature1 from "assets/svgs/feature-1.svg";
import Feature2 from "assets/svgs/feature-2.svg";
import Feature3 from "assets/svgs/feature-3.svg";
import Feature4 from "assets/svgs/feature-4.svg";
import Feature5 from "assets/svgs/feature-5.svg";
import Feature6 from "assets/svgs/feature-6.svg";
import Feature7 from "assets/svgs/feature-7.svg";
import Feature8 from "assets/svgs/feature-8.svg";
import AboutBanner from "components/elements/AboutBanner";
import SingleFeature from "components/elements/SingleFeature";

const Features = () => {
  const features = [
    {
      title: "Backtest",
      description:
        "No more guessing – create your strategy and test its results What if we told you that you could build a wide gamut of strategies and algorithms without ever having to write any code? What if you could sell what you build to other brokers, traders and investors? With KEEV, trading will be better and faster - better executions, faster real-time trading strategies.",
      isRtl: false,
      bg: Feature1,
    },
    {
      title: "Optimize",
      description:
        "Get the best possible returns with lowest losses create your strategy and test its results What if we told you that you could build a wide gamut of strategies and algorithms without ever having to write any code? What if you could sell what you build to other brokers, traders and investors? With KEEV, trading will be better and faster - better executions, faster real-time trading strategies.",
      isRtl: true,
      bg: Feature2,
    },
    {
      title: "Forward Test",
      description:
        "Forward test before putting your money on the lineWhat if we told you that you could build a wide gamut of strategies and algorithms without ever having to write any code? What if you could sell what you build to other brokers, traders and investors? With KEEV, trading will be better and faster - better executions, faster real-time trading strategies.",

      isRtl: false,
      bg: Feature3,
    },
    {
      title: "Automated Live Trading",
      description:
        "Stop spending hours in front of the screen What if we told you that you could build a wide gamut of strategies and algorithms without ever having to write any code? What if you could sell what you build to other brokers, traders and investors? With KEEV, trading will be better and faster - better executions, faster real-time trading strategies.",
      isRtl: true,
      bg: Feature4,
    },
    {
      title: "Multiple Brokers",
      description:
        "You can integrate multiple brokers but only one can be active at a time, you could build a wide gamut of strategies and algorithms without ever having to write any code? What if you could sell what you build to other brokers, traders and investors? With KEEV, trading will be better and faster - better executions, faster real-time trading strategies.",
      isRtl: false,
      bg: Feature5,
    },
    {
      title: "Connector",
      description:
        "Get the best possible returns with lowest losses create your strategy and test its results What if we told you that you could build a wide gamut of strategies and algorithms without ever having to write any code? What if you could sell what you build to other brokers, traders and investors? With KEEV, trading will be better and faster - better executions, faster real-time trading strategies.",
      isRtl: true,
      bg: Feature6,
    },
    {
      title: "Multiple Segments",
      description:
        "Forward test before putting your money on the line What if we told you that you could build a wide gamut of strategies and algorithms without ever having to write any code? What if you could sell what you build to other brokers, traders and investors? With KEEV, trading will be better and faster - better executions, faster real-time trading strategies.",
      bg: Feature7,
      isRtl: false,
    },
    {
      title: "Limit Order Placement",
      description:
        "Stop spending hours in front of the screen What if we told you that you could build a wide gamut of strategies and algorithms without ever having to write any code? What if you could sell what you build to other brokers, traders and investors? With KEEV, trading will be better and faster - better executions, faster real-time trading strategies.",
      bg: Feature8,
      isRtl: true,
    },
  ];
  return (
    <>
      <AboutBanner
        title="Easing your way into the complex world of trading with never-seen-before"
        description="Easing your way into the complex world of trading with never-seen-before"
      />
      {features.map((item, i) => {
        return (
          <SingleFeature
            title={item?.title}
            description={item?.description}
            isRtl={item?.isRtl}
            key={i}
            bg={item?.bg}
          />
        );
      })}
    </>
  );
};

export default Features;
