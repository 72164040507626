import {
  Button,
  Grid,
  GridItem,
  Image,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import Community from "assets/svgs/community.svg";

const JoinCommunity = () => {
  const [isSmallerThan425] = useMediaQuery("(max-width: 425px)");

  return (
    <Grid
      templateColumns={`repeat(${isSmallerThan425 ? 1 : 2}, 1fr)`}
      gap={4}
      px="10%"
      py="5%"
    >
      <GridItem w="100%">
        <Stack spacing={6} justifyContent="center" height="100%">
          <Text fontSize={28}>
            <Text as="span" color="#01a2bb">
              Join{" "}
            </Text>
            the community now
          </Text>
          <Text color="#6c6c6c" lineHeight={"28px"}>
            Step into a Forward Test Room experience, Show-off your skills
            and form an active investment community
          </Text>
          <Button
            borderRadius={"2px"}
            size="lg"
            fontSize={16}
            bg={"#01a2bb"}
            color="#fff"
            _hover={{
              bg: "#01a2bb",
            }}
            onClick={() =>
              window.open(
                "//web.telegram.org/k/#@keev_simplifying_algo",
                "_blank"
              )
            }
            width="fit-content"
          >
            Join Community
          </Button>
        </Stack>
      </GridItem>
      <GridItem w="100%">
        <Stack alignItems={"center"}>
          <Image src={Community} />
        </Stack>
      </GridItem>
    </Grid>
  );
};

export default JoinCommunity;
