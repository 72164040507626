import AboutBanner from "components/elements/AboutBanner";
import Building from "./Building";
import OurBackground from "./OurBackground";
// import OurTeam from "./OurTeam";

const AboutUs = () => {
  return (
    <>
      <AboutBanner
        title="India’s best, Algorithmic Trading Solution Platform"
        description="We are trading, research and finance experts"
      />
      <OurBackground />
      <Building />
      {/* <OurTeam /> */}
    </>
  );
};

export default AboutUs;
