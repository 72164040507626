import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Image,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import Logo from "assets/images/KEEV.png";
import Close from "assets/svgs/close.svg";
import HamBurger from "assets/svgs/hamburger.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Header = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSmallerThan425] = useMediaQuery("(max-width: 425px)");
  const [isSmallerThan1280] = useMediaQuery("(max-width: 1280px)");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const links = [
    {
      label: "Features",
      to: "/",
      onClick: () => navigate("/features"),
      link: "/features",
    },
    // {
    //   label: "Pricing",
    //   onClick: () => navigate("/pricing"),
    //   link: "/pricing",
    // },
    {
      label: "Knowledge Base",
      onClick: () =>
        window.open("//support.keev.tech/portal/en/kb/keev", "_blank"),
    },
    {
      label: "About Us",
      onClick: () => navigate("/about-us"),
      link: "/about-us",
    },
    {
      label: "Support",
      onClick: () =>
        window.open("//support.keev.tech/portal/en/newticket", "_blank"),
    },
    {
      label: "Blogs",
      onClick: () => navigate("/blogs"),
      link: "/blogs",
    },
  ];

  return (
    <>
      {/* <HStack
        height={51}
        bg="#00a3be"
        w="100%"
        position={"sticky"}
        top={0}
        left={0}
        right={0}
        alignItems={"center"}
        justifyContent={"center"}
        zIndex={2222}
      >
        <Text color="white" fontWeight={"700 !important"}>
          We are running an offer of{" "}
          <Text
            as="span"
            fontSize={16}
            fontWeight={"bold !important"}
            color="white"
          >
            {" "}
            10 % off
          </Text>{" "}
          Due to the overwhelming positive response, we have decided to extend
          our offer until January 10, 2024.
        </Text>

        <Link to="/pricing">
          <Text as="u" color="white" fontWeight={"700 !important"}>
            Know More
          </Text>
        </Link>
      </HStack> */}
      <HStack
        height={71}
        top={0}
        maxHeight={71}
        px={isSmallerThan425 ? "5%" : "10%"}
        justifyContent={"space-between"}
        borderBottom="1px solid #ECEDF5"
        position={"fixed"}
        left={0}
        width="100%"
        zIndex={999}
        background="#fff"
      >
        <Box>
          <Link to="/">
            <Image src={Logo} w={190} />
          </Link>
        </Box>

        {isSmallerThan425 ? (
          <Image src={isOpen ? Close : HamBurger} onClick={onOpen} />
        ) : (
          <>
            <HStack
              justifyContent={"center"}
              flex={isSmallerThan1280 ? 1.5 : 2}
              gap={isSmallerThan1280 ? 5 : 10}
            >
              {links.map((item, i) => {
                return (
                  <Text
                    fontWeight={500}
                    color={
                      location.pathname === item?.link ? "#01A2BB" : "#141414"
                    }
                    cursor={"pointer"}
                    key={i}
                    onClick={item.onClick}
                    position="relative"
                  >
                    <Box
                      position="absolute"
                      width={location.pathname === item?.link ? "25px" : 0}
                      height="2px"
                      bg="#01A2BB"
                      borderRadius={5}
                      bottom={-1}
                      transition="all 0.3s linear"
                    />

                    {item?.label}
                  </Text>
                );
              })}
            </HStack>
            {/* Action Buttons */}
            <HStack gap={4}>
              <Button
                color="#01A2BB"
                fontSize="16px"
                variant={"link"}
                onClick={() => window.open("//app.keev.tech/", "_blank")}
              >
                Login
              </Button>
              <Button
                color="#fff"
                fontSize="16px"
                borderRadius={"2px"}
                bg="#01A2BB"
                _hover={{
                  bg: "#01a2bb",
                }}
                onClick={() => window.open("//app.keev.tech/sign-up", "_blank")}
              >
                Join for Free
              </Button>
            </HStack>
          </>
        )}
      </HStack>
      {isOpen && (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
          <DrawerOverlay top="71px !important" />
          <DrawerContent top="71px !important">
            <DrawerBody p={0}>
              <Stack spacing={0}>
                {links.map((item, i) => {
                  return (
                    <>
                      <HStack justifyContent={"space-between"} p={4}>
                        <Text
                          fontWeight={500}
                          color="#141414"
                          cursor={"pointer"}
                          key={i}
                          onClick={() => {
                            onClose();
                            item.onClick();
                          }}
                        >
                          {item?.label}
                        </Text>
                        <ChevronRightIcon w={6} h={6} color="#888888" />
                      </HStack>
                      <Divider pt="0 !important" />
                    </>
                  );
                })}
                <HStack gap={4} p={4}>
                  <Button
                    color="#01A2BB"
                    fontSize="16px"
                    variant={"link"}
                    onClick={() => window.open("//app.keev.tech/", "_blank")}
                  >
                    Login
                  </Button>
                  <Button
                    color="#fff"
                    fontSize="16px"
                    borderRadius={"2px"}
                    bg="#01A2BB"
                    _hover={{
                      bg: "#01a2bb",
                    }}
                    onClick={() =>
                      window.open("//app.keev.tech/sign-up", "_blank")
                    }
                  >
                    Join for Free
                  </Button>
                </HStack>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

export default Header;
