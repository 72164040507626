import { Button, Flex, HStack, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import NoIcon from "assets/no.svg";
import YesIcon from "assets/yes.svg";
import styles from "../styles/styles.css";
const PricingCol = (props) => {
  const { data, isLast, multiplyBy, onClick, isPremium, selected } = props;

  const getPrice = Math.round(
    data?.price * (1 - multiplyBy?.save / 100) * multiplyBy?.unit
  );

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      <Stack
        bg="#01a2bb"
        p={2}
        h={"87px"}
        spacing={0}
        borderTopRightRadius={isLast && "5px"}
        position={"relative"}
        borderRight="2px solid #F6F5FA"
        borderBottom="2px solid #F6F5FA"
      >
        {/* <Text color="#F2F2F2" fontSize={18}>
          {data?.name === "Pro" || data?.name === "Premium" ? (
            <>
              {data?.name}{" "}
              <span style={{ fontSize: "15px", fontWeight: "bolder" }}>
                <b style={{ fontWeight: "inherit" }}> (with 10% Off)</b>
              </span>
            </>
          ) : (
            data?.name
          )}
        </Text> */}
        {data?.name === "Pro" || data?.name === "Premium" ? (
          <Flex>
            <Text color="#F2F2F2" fontSize={18}>
              {data?.name}
            </Text>
            <Text
              className={styles["bold-text"]}
              color="#F2F2F2"
              fontSize={18}
              ml={2}
            >
              ( 10% off )
            </Text>
          </Flex>
        ) : (
          <Text color="#F2F2F2" fontSize={18}>
            {data?.name}
          </Text>
        )}
        <Text color="#fff" fontSize={28} fontWeight={700}>
          {data?.price === 0
            ? "FREE"
            : `₹
          ${numberWithCommas(getPrice)}
          +GST`}
        </Text>
      </Stack>

      <Stack
        // bg="#F2F4FF"
        p={2}
        bg="#fff"
        borderRight="2px solid #F6F5FA"
        borderBottom="2px solid #F6F5FA"
      >
        <Text color="#141414" fontSize={18} visibility="hidden">
          Live
        </Text>
        <Text
          color="#141414"
          fontSize={18}
          display="flex"
          alignItems={"center"}
          gap={2}
        >
          <Image src={data?.daily_backtest ? YesIcon : NoIcon} />
          {/* {data?.daily_backtest ? numberWithCommas(data?.daily_backtest) : null}{" "} */}
          <Text as="span" color="#6c6c6c">
            {data?.daily_backtest}
          </Text>
        </Text>
        <Text
          color="#141414"
          fontSize={18}
          display="flex"
          alignItems={"center"}
          gap={2}
        >
          <Image src={data?.daily_option_backtest ? YesIcon : NoIcon} />
          {data?.daily_option_backtest
            ? numberWithCommas(data?.daily_option_backtest)
            : null}{" "}
          <Text as="span" color="#6c6c6c">
            {/* backtest */}
          </Text>
        </Text>
        {/* <Text color="#141414" fontSize={18} display="flex" alignItems={"center"} gap={2}>
            <Image src={data?.backtest?.quantity ? YesIcon : NoIcon} />
            {data?.backtest?.quantity ? "" : "Not"}
            <Text as="span" color="#6c6c6c">
              {data?.backtest?.quantity }
            </Text>
          </Text> */}
      </Stack>
      <Stack
        // bg="#F2F4FF"
        p={2}
        bg="#fff"
        borderRight="2px solid #F6F5FA"
        borderBottom="2px solid #F6F5FA"
      >
        <Text
          color="#141414"
          fontSize={18}
          display="flex"
          alignItems={"center"}
          gap={2}
        >
          <Image src={data?.total_optimisation > 0 ? YesIcon : NoIcon} />
          {data?.total_optimisation
            ? numberWithCommas(data?.total_optimisation)
            : null}{" "}
          <Text as="span" color="#6c6c6c">
            Optimization
          </Text>
        </Text>
      </Stack>
      <Stack
        // bg="#F2F4FF"
        p={2}
        bg="#fff"
        borderRight="2px solid #F6F5FA"
        borderBottom="2px solid #F6F5FA"
      >
        <Text
          color="#141414"
          fontSize={18}
          display="flex"
          alignItems={"center"}
          gap={2}
        >
          <Image src={data?.total_virtual_trade > 0 ? YesIcon : NoIcon} />
          {data?.total_virtual_trade
            ? numberWithCommas(data?.total_virtual_trade)
            : null}{" "}
          <Text as="span" color="#6c6c6c">
            Forward Trades
          </Text>
        </Text>
      </Stack>
      <Stack
        // bg="#F2F4FF"
        p={2}
        bg="#fff"
        borderRight="2px solid #F6F5FA"
        borderBottom="2px solid #F6F5FA"
      >
        <Text color="#141414" fontSize={18} visibility="hidden">
          Live
        </Text>
        <Text
          color="#141414"
          fontSize={18}
          display="flex"
          alignItems={"center"}
          gap={2}
        >
          <Image src={data?.total_live_trade > 0 ? YesIcon : NoIcon} />
          {data?.total_live_trade
            ? numberWithCommas(data?.total_live_trade)
            : null}{" "}
          {data?.total_live_trade > 0 ? (
            <Text as="span" color="#6c6c6c">
              Live Trading
            </Text>
          ) : (
            <Text as="span" color="#6c6c6c">
              Not available
            </Text>
          )}
        </Text>

        {/* <Text color="#141414" fontSize={18} display="flex" alignItems={"center"} gap={2}>
            <Image src={data?.live?.notifications ? YesIcon : NoIcon} />
            {data?.live?.notifications ? numberWithCommas(data?.live?.notifications * multiplyBy?.unit) : null}{" "}
            <Text as="span" color="#6c6c6c">
              Notifications
            </Text>
          </Text> */}
        <Text
          color="#141414"
          fontSize={18}
          display="flex"
          alignItems={"center"}
          gap={2}
        >
          <Image src={data?.is_auto ? YesIcon : NoIcon} />
          {data?.is_auto ? (
            <Text as="span" color="#6c6c6c">
              Fully Automated
            </Text>
          ) : (
            <Text as="span" color="#6c6c6c">
              Not Automated
            </Text>
          )}
        </Text>
        <Text
          color="#141414"
          fontSize={18}
          display="flex"
          alignItems={"center"}
          gap={2}
        >
          <Image src={YesIcon} />

          <Text as="span" color="#6c6c6c">
            Limit Order
          </Text>
        </Text>
      </Stack>
      <Stack
        // bg="#F2F4FF"
        p={2}
        bg="#fff"
        borderRight="2px solid #F6F5FA"
        borderBottom="2px solid #F6F5FA"
      >
        <Text
          color="#141414"
          fontSize={18}
          display="flex"
          alignItems={"center"}
          gap={2}
        >
          <Image src={data?.is_custom_indicator ? YesIcon : NoIcon} />
          <>
            {data?.is_custom_indicator ? (
              <Text as="span" color="#6c6c6c">
                Yes
              </Text>
            ) : (
              <Text as="span" color="#6c6c6c">
                No
              </Text>
            )}
          </>
        </Text>
      </Stack>

      <Stack
        // bg="#F2F4FF"
        p={2}
        bg="#fff"
        borderRight="2px solid #F6F5FA"
        borderBottom="2px solid #F6F5FA"
      >
        <Text
          color="#141414"
          fontSize={18}
          display="flex"
          alignItems={"center"}
          gap={2}
        >
          <Image src={YesIcon} />
          <Text as="span" color="#6c6c6c">
            Yes
          </Text>
        </Text>
      </Stack>
      <Stack
        p={2}
        bg="#fff"
        borderRight="2px solid #F6F5FA"
        borderBottom="2px solid #F6F5FA"
      >
        <Text
          color="#141414"
          fontSize={18}
          display="flex"
          alignItems={"center"}
          gap={2}
        >
          <Image src={data?.is_auto ? YesIcon : NoIcon} />
          {data?.is_auto ? (
            <Text as="span" color="#6c6c6c">
              Yes
            </Text>
          ) : (
            <Text as="span" color="#6c6c6c">
              No
            </Text>
          )}
        </Text>
      </Stack>
      <Stack
        p={2}
        bg="#fff"
        borderRight="2px solid #F6F5FA"
        borderBottom="2px solid #F6F5FA"
      >
        <Text
          color="#141414"
          fontSize={18}
          display="flex"
          alignItems={"center"}
          gap={2}
        >
          <Image src={data?.is_auto ? YesIcon : NoIcon} />
          <>
            {data?.is_auto ? (
              <Text as="span" color="#6c6c6c">
                Yes
              </Text>
            ) : (
              <Text as="span" color="#6c6c6c">
                No
              </Text>
            )}
            {/* Yes
              
              {
            "name": "Premium",
            "price": "16310.00",
            "total_backtest": 0,
            "total_optimisation": 600,
            "total_virtual_trade": 0,
            "total_live_trade": 0,
            "total_scanner": 600,
            "allowed_renko": true,
            "allowed_heikin_ashi": true,
            "segment_allowed": [],
            "duration_in_months": 12,
            "custom_time_interval": false,
            "is_auto": false,
            "is_custom_indicator": false,
            "is_active": true,
            "total_order_placement_api": 36000,
            "allowed_option_trade": false,
            "total_option_backtest": 0,
            "daily_backtest": 500,
            "daily_optimisation": 600,
            "daily_scanner": 600,
            "daily_option_backtest": 100,
            "time_based_option_trading":Boolean,
            "notification_based_live_trading":Boolean,
            "fully_automated_live_trading":Boolean,
            "option_strategy_real_time":Boolean,
            "option_strategy_limit_order":Boolean,
            "virtul_assistance":Boolean,
            "external_orders":Number,
            "live_limit_order":Boolean
              }
              */}
          </>
        </Text>
      </Stack>
      <Stack
        // bg="#F2F4FF"
        p={2}
        bg="#fff"
        borderRight="2px solid #F6F5FA"
        borderBottom="2px solid #F6F5FA"
      >
        <Text
          color="#141414"
          fontSize={18}
          display="flex"
          alignItems={"center"}
          gap={2}
        >
          <Image src={data?.allowed_heikin_ashi ? YesIcon : NoIcon} />
          {data?.allowed_heikin_ashi ? (
            <Text as="span" color="#6c6c6c">
              Yes
            </Text>
          ) : (
            <Text as="span" color="#6c6c6c">
              No
            </Text>
          )}
        </Text>
      </Stack>
      <Stack
        // bg="#F2F4FF"
        p={2}
        bg="#fff"
        borderRight="2px solid #F6F5FA"
        borderBottom="2px solid #F6F5FA"
      >
        <Text color="#141414" fontSize={18} visibility="hidden">
          Option Strategy
        </Text>

        <Text
          color="#141414"
          fontSize={18}
          display="flex"
          alignItems={"center"}
          gap={2}
        >
          <Image src={YesIcon} />
          <Text as="span" color="#6c6c6c">
            Real-time
          </Text>
        </Text>
        <Text
          color="#141414"
          fontSize={18}
          display="flex"
          alignItems={"center"}
          gap={2}
        >
          <Image src={YesIcon} />
          <Text as="span" color="#6c6c6c">
            Yes
          </Text>
        </Text>
      </Stack>

      <Stack
        // bg="#F2F4FF"
        p={2}
        bg="#fff"
        borderRight="2px solid #F6F5FA"
        borderBottom="2px solid #F6F5FA"
      >
        <Text
          color="#141414"
          fontSize={18}
          display="flex"
          alignItems={"center"}
          gap={2}
        >
          <Image src={data?.daily_scanner > 0 ? YesIcon : NoIcon} />
          {data?.daily_scanner
            ? numberWithCommas(data?.daily_scanner)
            : null}{" "}
          <Text as="span" color="#6c6c6c">
            Scanners
          </Text>
        </Text>
      </Stack>
      <Stack
        p={2}
        bg="#fff"
        borderRight="2px solid #F6F5FA"
        borderBottom="2px solid #F6F5FA"
      >
        <Text
          color="#141414"
          fontSize={18}
          display="flex"
          alignItems={"center"}
          gap={2}
        >
          {data?.allowed_renko ? (
            <Text as="span" color="#6c6c6c">
              Coming Soon
            </Text>
          ) : (
            <Text as="span" color="#6c6c6c">
              Coming Soon
            </Text>
          )}
        </Text>
      </Stack>
      <Stack
        p={2}
        bg="#fff"
        borderRight="2px solid #F6F5FA"
        borderBottom="2px solid #F6F5FA"
      >
        <Text
          color="#141414"
          fontSize={18}
          display="flex"
          alignItems={"center"}
          gap={2}
        >
          <Image src={YesIcon} />

          <Text as="span" color="#6c6c6c">
            {/* NSE, NFO-FUT, NFO-OPT */}

            {data?.segment_allowed?.map((ele, index) => (
              <React.Fragment key={index}>
                {ele?.display_name}
                {index !== data.segment_allowed.length - 1 && " "}
              </React.Fragment>
            ))}
          </Text>
        </Text>
      </Stack>
      {/* <Stack
          bg="#fff"
          p={2}
          borderRight="2px solid #F6F5FA"
          borderBottom="2px solid #F6F5FA"
          justifyContent={"center"}
        >
          <Text color="#141414" fontSize={18} display="flex" alignItems={"center"} gap={2}>
            <Image src={data?.virtualAssistance ? YesIcon : NoIcon} />
            {data?.virtualAssistance ? (
              <Text as="span" color="#6c6c6c">
                Scheduled (30 Minutes)
              </Text>
            ) : (
              <Text as="span" color="#6c6c6c">
                No
              </Text>
            )}
          </Text>
        </Stack> */}

      <Stack
        bg="#fff"
        p={2}
        borderRight="2px solid #F6F5FA"
        borderBottom="2px solid #F6F5FA"
        maxH="150px"
        h="150px"
        justifyContent={"center"}
      >
        <Text
          color="#141414"
          fontSize={18}
          display="flex"
          alignItems={"center"}
          gap={2}
        >
          <Image src={data?.total_order_placement_api > 0 ? YesIcon : NoIcon} />
          {data?.total_order_placement_api
            ? numberWithCommas(data?.total_order_placement_api)
            : null}{" "}
          <Text as="span" color="#6c6c6c">
            Order placements
          </Text>
        </Text>
      </Stack>

      <Stack
        bg="#EBFAFC"
        borderRight="2px solid #F6F5FA"
        borderBottom="2px solid #F6F5FA"
        maxHeight="123px"
        height="100%"
        display="flex"
        p={4}
        justifyContent={"center"}
      >
        <Button
          borderRadius={"2px"}
          bg="#01a2bb"
          color="#fff"
          _hover={{
            bg: "#01a2bb",
          }}
          onClick={() =>
            data?.price === 0
              ? window.open("//app.keev.tech/signup")
              : window.open("//app.keev.tech/pricing")
          }
        >
          {"Sign Up Now"}
        </Button>
      </Stack>
    </>
  );
};

export default PricingCol;
