import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  GridItem,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import PricingCol from "components/elements/PricingCol";
import { useState } from "react";

const Pricing = () => {
  const [selected, setSelected] = useState(0);

  const buttons = [
    {
      label: "Monthly",
      tooltip: "",
    },
    {
      label: "Quarterly",
      tooltip: "Save 4%",
    },
    {
      label: "Yearly",
      tooltip: "Save 20%",
    },
  ];

  const pricingTable = [
    selected === 0
      ? {
          name: "Starter",
          price: 0,
          total_backtest: 500,
          total_optimisation: 10,
          total_virtual_trade: 5,
          total_live_trade: 5,
          total_scanner: 10,
          total_order_placement_api: 10,
          total_funds_api: 100000,
          total_holdings_api: 0,
          total_positions_api: 100000,
          total_order_history_api: 100000,
          allowed_renko: false,
          allowed_heikin_ashi: true,
          segment_allowed: [
            {
              name: "nse_fo",
              display_name: "NFO",
            },
            {
              name: "nse_eq",
              display_name: "NSE",
            },
          ],
          duration_in_months: 1,
          custom_time_interval: false,
          is_auto: true,
          is_custom_indicator: true,
          is_active: true,
          total_virtual_order_placement_api: 50,
          allowed_option_trade: true,
          total_option_backtest: 0,
          daily_backtest: 20,
          daily_optimisation: 30,
          daily_scanner: 100,
          daily_option_backtest: 10,
        }
      : null,
    {
      id: "f08910d7-a9f1-4541-8088-46c2d6b5da28",
      name: "Basic",
      price: "500.00",
      total_backtest: 5000,
      total_optimisation: 10,
      total_virtual_trade: 5,
      total_live_trade: 5,
      total_scanner: 10,
      allowed_renko: true,
      allowed_heikin_ashi: true,
      segment_allowed: [
        {
          name: "nse_fo",
          display_name: "NFO",
        },
        {
          name: "nse_eq",
          display_name: "NSE",
        },
      ],
      duration_in_months: 1,
      custom_time_interval: false,
      is_auto: false,
      is_custom_indicator: false,
      is_active: true,
      total_order_placement_api: 100,
      allowed_option_trade: true,
      total_option_backtest: 100,
      daily_backtest: 100,
      daily_optimisation: 10,
      daily_scanner: 10,
      daily_option_backtest: 20,
    },
    {
      id: "d4ef3b48-05d4-49d4-9cda-e10ff96e6fca",
      name: "Pro",
      price: "855.00",
      total_backtest: 20000,
      total_optimisation: 20,
      total_virtual_trade: 5,
      total_live_trade: 20,
      total_scanner: 20,
      allowed_renko: false,
      allowed_heikin_ashi: true,
      segment_allowed: [
        {
          name: "nse_fo",
          display_name: "NFO",
        },
        {
          name: "nse_eq",
          display_name: "NSE",
        },
      ],
      duration_in_months: 1,
      custom_time_interval: false,
      is_auto: true,
      is_custom_indicator: true,
      is_active: true,
      total_order_placement_api: 1000,
      allowed_option_trade: true,
      total_option_backtest: 100,
      daily_backtest: 200,
      daily_optimisation: 20,
      daily_scanner: 20,
      daily_option_backtest: 50,
    },
    {
      id: "be39079a-922d-4f60-8c76-bd4f62c000f9",
      name: "Premium",
      price: "1529.00",
      total_backtest: 10000,
      total_optimisation: 50,
      total_virtual_trade: 10,
      total_live_trade: 50,
      total_scanner: 50,
      allowed_renko: false,
      allowed_heikin_ashi: true,
      segment_allowed: [
        {
          name: "nse_fo",
          display_name: "NFO",
        },
        {
          name: "nse_eq",
          display_name: "NSE",
        },
      ],
      duration_in_months: 1,
      custom_time_interval: true,
      is_auto: true,
      is_custom_indicator: true,
      is_active: true,
      total_order_placement_api: 3000,
      allowed_option_trade: true,
      total_option_backtest: 100,
      daily_backtest: 500,
      daily_optimisation: 50,
      daily_scanner: 50,
      daily_option_backtest: 100,
    },
  ];

  const multiplySec = {
    0: {
      save: 0,
      unit: 1,
    },
    1: {
      save: 4,
      unit: 3,
    },
    2: {
      save: 20,
      unit: 12,
    },
  };

  return (
    <Stack px={"10%"} py={"2%"} mb={"2rem !important"} mt={"71px !important"}>
      <Text fontSize={28} color="#141414">
        <Text color="#01a2bb" as="span">
          Plans{" "}
        </Text>
        that fit your scale
      </Text>
      <ButtonGroup variant="outline" isAttached>
        {buttons.map((item, i) => {
          return (
            <Tooltip
              key={i}
              label={item.tooltip}
              isOpen
              hasArrow
              bg={"#fff"}
              fontSize={16}
              color={"#01a2bb"}
              border={"1.5px solid #01a2bb"}
              borderRadius={"5px"}
              arrowShadowColor="#01a2bb"
              shadow="0"
              // zIndex={2}
            >
              <Button
                borderRadius={0}
                size="lg"
                fontSize={16}
                bg={selected === i ? "#01a2bb" : "#EBFAFC"}
                color={selected === i ? "#fff" : "#888888"}
                onClick={() => setSelected(i)}
                borderColor={selected === i ? "#01a2bb" : "#EBFAFC"}
                _hover={{
                  bg: selected === i ? "#01a2bb" : "#EBFAFC",
                }}
                key={i}
              >
                {item.label}
              </Button>
            </Tooltip>
          );
        })}
      </ButtonGroup>

      <Grid templateColumns="repeat(5, 1fr)" gap={0} mt={"65px !important"}>
        <GridItem w="100%">
          <Stack
            bg="#01a2bb"
            p={2}
            spacing={0}
            borderTopLeftRadius={"5px"}
            borderRight="2px solid #fff"
            borderBottom="2px solid #fff"
          >
            <Text color="#F2F2F2" fontSize={18}>
              Features/Plans
            </Text>
            <Text color="#fff" fontSize={28} fontWeight={600}>
              Pricing
            </Text>
          </Stack>
          <Stack
            p={2}
            bg="#fff"
            borderRight="2px solid #F6F5FA"
            borderBottom="2px solid #F6F5FA"
          >
            <Text
              color="#141414"
              display="flex"
              alignItems="center"
              flexDirection="row"
              fontSize={18}
            >
              <span style={{ marginRight: "8px" }}>BackTest</span>
            </Text>
            {/* Live Trading  */}
            <Text color="#888888" fontSize={18}>
              Daily Backtest
            </Text>
            <Text color="#888888" fontSize={18}>
              Daily Option Backtest
            </Text>
            {/* <Text color="#888888" fontSize={18}>
                Total BackTest
              </Text> */}
          </Stack>
          <Stack
            p={2}
            bg="#fff"
            borderRight="2px solid #F6F5FA"
            borderBottom="2px solid #F6F5FA"
            flexDirection="row"
          >
            <Text color="#141414" fontSize={18} mr="8px">
              Optimize
            </Text>
          </Stack>
          <Stack
            p={2}
            bg="#fff"
            borderRight="2px solid #F6F5FA"
            borderBottom="2px solid #F6F5FA"
            flexDirection="row"
          >
            <Text color="#141414" fontSize={18} mr="8px">
              Forward Test (Active at Time)
            </Text>
          </Stack>
          <Stack
            p={2}
            bg="#fff"
            borderRight="2px solid #F6F5FA"
            borderBottom="2px solid #F6F5FA"
          >
            <Text
              color="#141414"
              display="flex"
              alignItems="center"
              flexDirection="row"
              fontSize={18}
            >
              <span style={{ marginRight: "8px" }}>Live</span>
            </Text>
            Live Trading (Active at Time)
            <Text color="#888888" fontSize={18}>
              Live Trading (Active at Time)
            </Text>
            {/* <Text color="#888888" fontSize={18}>
                Notifications
              </Text> */}
            <Text color="#888888" fontSize={18}>
              Fully Automated
            </Text>
            <Text color="#888888" fontSize={18}>
              Limit Order
            </Text>
          </Stack>
          <Stack
            // bg="#F2F4FF"
            p={2}
            bg="#fff"
            borderRight="2px solid #F6F5FA"
            borderBottom="2px solid #F6F5FA"
          >
            <Text
              color="#141414"
              display="flex"
              alignItems="center"
              flexDirection="row"
              fontSize={18}
            >
              <span style={{ marginRight: "8px" }}>
                Indicator-based Option Trading
              </span>
            </Text>
          </Stack>
          <Stack
            // bg="#F2F4FF"
            p={2}
            bg="#fff"
            borderRight="2px solid #F6F5FA"
            borderBottom="2px solid #F6F5FA"
          >
            <Text
              color="#141414"
              display="flex"
              alignItems="center"
              flexDirection="row"
              fontSize={18}
            >
              <span style={{ marginRight: "8px" }}>
                {" "}
                Time-based Option Trading
              </span>
            </Text>
          </Stack>
          <Stack
            // bg="#F2F4FF"
            p={2}
            bg="#fff"
            borderRight="2px solid #F6F5FA"
            borderBottom="2px solid #F6F5FA"
          >
            <Text
              color="#141414"
              display="flex"
              alignItems="center"
              flexDirection="row"
              fontSize={18}
            >
              <span style={{ marginRight: "8px" }}>
                {" "}
                Notification-based Live Trading
              </span>
            </Text>
          </Stack>

          <Stack
            // bg="#F2F4FF"
            p={2}
            bg="#fff"
            borderRight="2px solid #F6F5FA"
            borderBottom="2px solid #F6F5FA"
          >
            <Text
              color="#141414"
              display="flex"
              alignItems="center"
              flexDirection="row"
              fontSize={18}
            >
              <span style={{ marginRight: "8px" }}>
                {" "}
                Fully-Automated Live Trading
              </span>
            </Text>
          </Stack>
          <Stack
            // bg="#F2F4FF"
            p={2}
            bg="#fff"
            borderRight="2px solid #F6F5FA"
            borderBottom="2px solid #F6F5FA"
          >
            <Text
              color="#141414"
              display="flex"
              alignItems="center"
              flexDirection="row"
              fontSize={18}
            >
              <span style={{ marginRight: "8px" }}> Heniksi Ashi</span>
            </Text>
          </Stack>
          <Stack
            // bg="#F2F4FF"
            p={2}
            bg="#fff"
            borderRight="2px solid #F6F5FA"
            borderBottom="2px solid #F6F5FA"
          >
            <Text
              color="#141414"
              display="flex"
              alignItems="center"
              flexDirection="row"
              fontSize={18}
            >
              <span style={{ marginRight: "8px" }}>Option Strategy</span>
            </Text>
            <Text color="#888888" fontSize={18}>
              Real-Time
            </Text>
            <Text color="#888888" fontSize={18}>
              Limit Order
            </Text>
          </Stack>
          <Stack
            p={2}
            bg="#fff"
            borderRight="2px solid #F6F5FA"
            borderBottom="2px solid #F6F5FA"
            flexDirection="row"
          >
            <Text color="#141414" fontSize={18} mr="8px">
              Scanners
            </Text>
          </Stack>
          <Stack
            p={2}
            bg="#fff"
            borderRight="2px solid #F6F5FA"
            borderBottom="2px solid #F6F5FA"
            flexDirection="row"
          >
            <Text color="#141414" fontSize={18} mr="8px">
              Renko
            </Text>
          </Stack>
          <Stack
            p={2}
            bg="#fff"
            borderRight="2px solid #F6F5FA"
            borderBottom="2px solid #F6F5FA"
            flexDirection="row"
          >
            <Text color="#141414" fontSize={18} mr="8px">
              Exchange
            </Text>
          </Stack>

          {/* <Stack p={2} bg="#fff" borderRight="2px solid #F6F5FA" borderBottom="2px solid #F6F5FA" flexDirection="row">
              <Text color="#141414" fontSize={18} mr="8px">
                Virtul Assistance
              </Text>
            </Stack> */}

          <Stack
            p={2}
            bg="#fff"
            borderRight="2px solid #F6F5FA"
            borderBottom="2px solid #F6F5FA"
          >
            <Text
              display="flex"
              color="#141414"
              alignItems="center"
              flexDirection="row"
              fontSize={18}
            >
              External Orders
            </Text>
            <Text color="#888888" fontSize={18}>
              Tradingview
            </Text>
            <Text color="#888888" fontSize={18}>
              Ami Broker
            </Text>
            <Text color="#888888" fontSize={18}>
              Python & Others
            </Text>
          </Stack>
          <Stack
            bg="#EBFAFC"
            borderRight="2px solid #F6F5FA"
            borderBottom="2px solid #F6F5FA"
            maxHeight="123px"
            height="100%"
            display="flex"
            p={4}
            justifyContent={"center"}
          >
            <Box
              borderRadius={"2px"}
              bg="#01a2bb"
              color="#fff"
              _hover={{
                bg: "#01a2bb",
              }}
            >
              <Button
                as={"p"}
                borderRadius={"2px"}
                bg="#01a2bb"
                color="#fff"
                _hover={{
                  bg: "#01a2bb",
                }}
                _active={{
                  bg: "#01a2bb",
                }}
              >
                {"Select the plan that help you grow "}
              </Button>
              <Button
                as={"p"}
                borderRadius={"2px"}
                mt={"-10px"}
                bg="#01a2bb"
                color="#fff"
                _hover={{
                  bg: "#01a2bb",
                }}
                _active={{
                  bg: "#01a2bb",
                }}
              >
                {"Read all plans or contact us we will guide you better"}
              </Button>
            </Box>
          </Stack>
        </GridItem>

        {pricingTable
          .filter((element) => element !== null)
          .map((item, i) => {
            return (
              <GridItem w="100%" key={i}>
                <PricingCol
                  data={item}
                  isLast={i === pricingTable.length - 1}
                  multiplyBy={multiplySec[selected]}
                  selected={selected}
                />
              </GridItem>
            );
          })}
      </Grid>
    </Stack>
  );
};

export default Pricing;
