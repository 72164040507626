import { Stack, Text } from "@chakra-ui/react";

const AboutBanner = (props) => {
  const { title, description } = props;
  return (
    <Stack
      bg="#01A2BB"
      px="10%"
      py="5%"
      alignItems={"center"}
      marginTop="71px !important"
    >
      <Text fontSize={28} color="#fff" textAlign="center">
        {title}
      </Text>
      <Text color="#fff" textAlign="center">
        {description}
      </Text>
    </Stack>
  );
};

export default AboutBanner;
