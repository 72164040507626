import { Grid, GridItem, Image, Stack, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import Offer1 from "assets/svgs/offer-1.svg";
import Offer2 from "assets/svgs/offer-2.svg";
import Offer3 from "assets/svgs/offer-3.svg";
import Offer4 from "assets/svgs/offer-4.svg";
import Offer5 from "assets/svgs/offer-5.svg";
import Offer6 from "assets/svgs/offer-6.svg";
import Offer7 from "assets/svgs/offer-7.svg";
import Offer8 from "assets/svgs/offer-8.svg";

const WhatWeOffer = (props) => {
  const { features } = props;
  const [isSmallerThan425] = useMediaQuery("(max-width: 425px)");

  const content = [
    {
      label: "Backtest",
      description: "No more guessing – create your strategy and test its results",
      image: Offer1,
    },
    {
      label: "Optimize",
      description: "Get the best possible returns with lowest losses ",
      image: Offer2,
    },
    {
      label: "Forward Test",
      description: "Forward test before putting your money on the line",
      image: Offer3,
    },
    {
      label: "Automated Live Trading",
      description: "Stop spending hours in front of the screen",
      image: Offer4,
    },
    {
      label: "20 Brokers & Counting",
      description: "Integrate multiple brokers at once",
      image: Offer5,
    },
    {
      label: "Connector",
      description: "Fine tune your strategy to get the best result possible",
      image: Offer6,
    },
    {
      label: "Multiple Segments",
      description: "Trade in equity, futures & options.",
      image: Offer7,
    },
    {
      label: "Limit order placement",
      description: "You can place limit orders to execute your trades at your given price",
      image: Offer8,
    },
  ];
  return (
    <Stack px={isSmallerThan425 ? "5%" : "10%"} py={"5%"} bg="#F5F5FF" spacing={4} ref={features}>
      <Text fontSize={28} color="#141414">
        <Text as="span" color="#01a2bb">
          What
        </Text>{" "}
        KEEV offers
      </Text>
      <Grid templateColumns={`repeat(${isSmallerThan425 ? 2 : 4}, 1fr)`} gap={4}>
        {content.map((item, i) => {
          return (
            <GridItem w="100%" key={i}>
              <Stack>
                <Image src={item.image} height={"50px"} width="fit-content" />
                <Text fontSize={20} color="#141414">
                  {item?.label}
                </Text>
                <Text color="#6c6c6c" lineHeight={"28px"}>
                  {item?.description}
                </Text>
              </Stack>
            </GridItem>
          );
        })}
      </Grid>
    </Stack>
  );
};

export default WhatWeOffer;
