import { Spinner, Stack } from "@chakra-ui/react";
import styles from "assets/styles/blogs.module.css";
import axios from "axios";
import HTMLReactParser from "html-react-parser";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const SingleBlog = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const addClassNameToCollection = (coll, cName) => {
    for (var i = 0; i < coll.length; i++) {
      coll[i].classList.add(styles[cName]);
    }
  };

  let h3 = document.getElementsByTagName("h3");
  let p = document.getElementsByTagName("p");
  let strong = document.getElementsByTagName("strong");

  useEffect(() => {
    addClassNameToCollection(h3, "h3");
    addClassNameToCollection(p, "p");
    addClassNameToCollection(strong, "strong");
  }, [h3, p, strong, isLoading]);

  const fetchBlogs = async () => {
    try {
      const res = axios.get("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@algokeev");
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBlogs().then((res) => {
      setBlog(res?.data?.items.find((ele) => ele.title.includes(id.split("-").join(" ").replace("%C2%A0", ""))));
      setIsLoading(false);
    });
  }, [id]);

  return !isLoading ? (
    <Stack px="10%" py="2%" marginTop="71px" id="single-blog">
      {HTMLReactParser(blog ? blog.content : "")}
    </Stack>
  ) : (
    <Stack px="10%" py="2%" marginTop="71px" height="100vh" alignItems={"center"} justifyContent="center">
      <Spinner color="#01a2bb" size="xl" />
    </Stack>
  );
};

export default SingleBlog;
