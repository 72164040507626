import Guide from "components/views/Guide";
import HeroSection from "components/views/HeroSection";
import Traditional from "components/views/Traditional";
import WhatWeOffer from "components/views/WhatWeOffer";
import OurApp from "components/views/OurApp";
import OurPartners from "components/views/OurPartners";
import JoinCommunity from "./JoinCommunity";
const Main = (props) => {
  const { features } = props;
  return (
    <>
      <HeroSection />
      <Guide />
      <Traditional />
      <WhatWeOffer features={features} />
      <JoinCommunity />
      <OurPartners />
      <OurApp />
    </>
  );
};

export default Main;
