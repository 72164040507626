import { Button, Grid, GridItem, HStack, Image, Stack, Text, useMediaQuery } from "@chakra-ui/react";
import Herocard from "assets/images/123.png";

const HeroSection = () => {
  const [isSmallerThan425] = useMediaQuery("(max-width: 425px)");

  return (
    <Grid
      templateColumns="repeat(2, 1fr)"
      gap={4}
      px={isSmallerThan425 ? "5%" : "10%"}
      height="calc(100vh - 71px)"
      gridAutoFlow={isSmallerThan425 && "dense"}
      marginTop="71px"
    >
      <GridItem w="100%" colSpan={isSmallerThan425 && 2}>
        <Stack height="100%" justifyContent={"center"}>
          <Text textTransform={"uppercase"} fontSize={18}>
            Level up your trading game.
          </Text>
          <Text textTransform={"uppercase"} color="#01a2bb" fontSize={28}>
            Simplifying automated trading
          </Text>
          <Text color="#6c6c6c">Use our No-code platform and NEVER miss a trade again!</Text>
          <HStack marginTop={"1.5rem !important"} spacing={4}>
            <Button
              borderRadius={"2px"}
              size="lg"
              fontSize={16}
              bg={"#01a2bb"}
              color="#fff"
              _hover={{
                bg: "#01a2bb",
              }}
              onClick={() => window.open("//app.keev.tech/sign-up", "_blank")}
            >
              Join for Free
            </Button>
            <Button
              borderRadius={"2px"}
              size="lg"
              fontSize={16}
              borderColor={"#01a2bb"}
              color="#01a2bb"
              variant="outline"
              onClick={() => window.open("//www.youtube.com/channel/UC_bW6BJvjk9m06n62tl13UQ", "_blank")}
            >
              How it works
            </Button>
          </HStack>
        </Stack>
      </GridItem>
      <GridItem w="100%" colSpan={isSmallerThan425 && 2}>
        <Stack justifyContent={"center"} height="100%">
          <Image src={Herocard} />
        </Stack>
      </GridItem>
    </Grid>
  );
};

export default HeroSection;
