import {
  Box,
  Grid,
  GridItem,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";

const SingleFeature = (props) => {
  const { title, description, isRtl, bg } = props;
  const [isSmallerThan425] = useMediaQuery("(max-width: 425px)");

  return (
    <Grid
      templateColumns={`repeat(${isSmallerThan425 ? 1 : 2}, 1fr)`}
      gap={6}
      px="10%"
      py="5%"
      dir={isRtl && "rtl"}
      bg={isRtl && "#F5F5FF"}
    >
      <GridItem w="100%" textAlign={"left"}>
        <Text fontSize={28} mb={4}>
          {title}
        </Text>
        <Text color="#6c6c6c" lineHeight={"28px"}>
          {description}
        </Text>
      </GridItem>
      <GridItem w="100%">
        <Box w="100%" height="250px" bg="">
          <Image
            src={bg}
            width="100%"
            height="100%"
            objectFit={"contain"}
            backgroundPosition="bottom"
          />
        </Box>
      </GridItem>
    </Grid>
  );
};

export default SingleFeature;
